.select-search .select-search__control {
    background-color: #F3F6F9;
    border-color: #F3F6F9 !important;
    color: #464E5F;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border-radius: 0.42rem;
    box-shadow: none;

    .select-search__value-container, .select-search__indicators {
        padding: 0.7rem 1.5rem;
    }
    &.select-search__control--is-focused {
        border: solid 1px #1880e3 !important;
        box-shadow: 0 0 3px #1880e3;
    }
}