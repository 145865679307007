// 3rd-party Plugins
// @import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light._scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark._scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light._scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark._scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark._scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light._scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
      overflow-y: auto;
      display: block;

      span {
        background: none transparent !important;
      }
  }
}

.json > pre {
  background: none #fff !important;

}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
        color: $primary !important;
    }
  }
}

// TRAINERON ===================================================================================

@import "_scss/react-select-search";


.btn-outside-hover .btn-element-hover {
  opacity: .16;
  transition: opacity .3s;
}
.btn-outside-hover:hover .btn-element-hover {
  opacity: 1 !important;
}
.btn-outside-hover .btn-outside-hover .btn-element-hover {
  opacity: .16 !important;
  transition: opacity .3s;
}
.btn-outside-hover .btn-outside-hover:hover .btn-element-hover {
  opacity: 1 !important;
}
.btn-outside-hover .show .btn-element-hover {
  opacity: 1 !important;
}
.opacity-0 {
  opacity: 0 !important;
}

input.is-valid, select.is-valid, div.is-valid {
  background-image: none !important;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.modal-nested {
  z-index: 1060;
  & + div[role="dialog"] {
    z-index: 1070;
  }
}

// WYSIWYG editor
.rdw-link-modal,
.rdw-embedded-modal,
.rdw-dropdown-optionwrapper {
  width: auto !important;
  height: auto !important;
}
.rdw-colorpicker-modal-options {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.rdw-block-dropdown {
  width: 130px !important;
}
.rdw-dropdown-optionwrapper {
  overflow-y: auto !important;
}
.rdw-fontsize-dropdown {
  width: 70px !important;
}
.justify-content-evenly {
  justify-content: space-evenly;
}
.is-fetching {
  opacity: .4;
}
.opacity-25 {
  opacity: .25;
}
.opacity-50 {
  opacity: .50;
}
.opacity-60 {
  opacity: .50;
}
.opacity-75 {
  opacity: .75;
}
.bg-dark-75 {
  background-color: lighten(#212121, .75);
}

// PLYR
:root {
  --plyr-color-main: #df0024;
}
.plyr {
  border-radius: 6px;
}

.pure-tab-content .tab-content {
  background-color: transparent;
}

.text-pre-wrap {
  white-space: pre-wrap;
}
.aside-enabled .get-aside-left-margin {
    left: 265px;
}
.aside-minimize .get-aside-left-margin {
   left: 70px;
 }

.styledTabsBg {
  background-image: linear-gradient(to top, #0001, transparent);
}
.styledTabs {
  border: 0;
  position: relative;
  background-image: linear-gradient(to top, #0001, transparent);
  margin-right: auto;
  align-self: flex-end;
  order: 1;
  @media (min-width: 992px) {
    order: 0;
  }
  &.styledTabs--noContent + .tab-content {
    display: none;
  }
  &.styledTabs--white .nav-link.active {
    background-color: #fff;
  }
  .nav-link {
    background-color: #40424914;
    border: 0;
    &.active {
      background-color: #eef0f8;
      box-shadow: 0 -0.55rem 0.75rem -0.2rem #0002;
      color: #80808F;
    }
  }
  + .tab-content {
    padding-top: 1rem;
  }
}
.styledTabsBg .styledTabs {
  background-image: none;
}

.featureRow:nth-child(even) {
  background: #F3F6F9;
}
.spaceButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border-width: 2px;
  border-style: dashed;
  border-color: currentColor;
  &:hover {
    background-color: #00000008;
  }
}

.flex-1 {
  flex: 1;
}

.minw-0 {
  min-width: 0;
}

.ellipsis {
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

@import 'react-credit-cards/lib/styles.scss'; // card preview

@media screen and (max-width: 400px) {
  $rccs-size: 200px;
  $rccs-name-font-size: 14px;
  $rccs-number-font-size: 15px;
  $rccs-valid-font-size: 8px;
  $rccs-expiry-font-size: 14px;
  @import 'react-credit-cards/lib/styles.scss'; // card preview
}

.transition-transform-3 {
  transition: transform .3s;
}

.fb_iframe_widget iframe[class=""] {
  display: none;
}

@import "_scss/hover";

.react-contexify {
  z-index: 2;
  border-radius: 5px;
}
.select-search__menu {
  z-index: 2 !important;
}

/* BOOTSTRAP OVERRIDES */
.input-group-append, .input-group-prepend {
  .input-group-text {
    border: none;
  }
}

.textBubbleTriangle--left {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 10px;
    left: -25px;
    border-right: solid 10px #F3F6F9;
    border-bottom: solid 17px transparent;
    border-left: solid 17px transparent;
    border-top: solid 17px transparent;
  }
}

form strong {
  color: #9696ad;
  margin-bottom: 0.5em;
  display: inline-block;
}

td.datatable-cell {
  border: none !important;
}

.pointer-events-none {
  pointer-events: none;
}
.no-dropdown-icon {
  .dropdown-toggle::after {
    display: none;
  }
}
.word-break-word {
  word-break: break-word;
}

.break-word {
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}
.break-spaces {
  white-space: break-spaces;
}

.rotate-18 {
  transform: rotate(18deg);
}

.force-child-font-size * {
  font-size: inherit !important;
  font-family: inherit !important;
}