.small-90, .small-90 label {
    font-size: 90% !important;
    font-weight: normal;
}
.small-85, .small-85 label {
    font-size: 85% !important;
    //font-weight: normal;
}
.small-80, .small-80 label {
    font-size: 80% !important;
    //font-weight: normal;
}
.small-65 {
    font-size: 65%;
    font-weight: normal;
}
.small-60 {
    font-size: 60%;
    font-weight: normal;
}
.small-55 {
    font-size: 55%;
    font-weight: normal;
}
.small-70, .small-70 label {
    font-size: 70% !important;
    font-weight: normal;
}
.wcag-xsmall {
    font-size: 10px !important;
    .wcag-xsmall {
        display: inline !important;
        font-size: 8px !important;
    }
}
.wcag-small {
    font-size: 12px !important;
    .wcag-small {
        display: inline!important;
        font-size: 8px !important;
    }
}
.wcag-large {
    font-size: 17px !important;
    .wcag-large {
        display: inline !important;
        font-size: 8px !important;
    }
}
.wcag-xlarge {
    font-size: 19px !important;
    .wcag-xlarge {
        display: inline !important;
        font-size: 8px !important;
    }
}
.wcag-high-contrast {
    *, .btn {
        background-color: #000 !important;
        color: #f0ea7a !important;
    }
    .card, .btn {
        border: #f0ea7a solid 1px !important;
    }
    *.active, *.active * {
        background-color: #f0ea7a !important;
        color: #000 !important;
    }
    .header-logo {
        img {
            display: none;
        }
        .wcag-high-contrast-logo {
            display: block !important;
        }
    }
}
